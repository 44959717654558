import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DefaultHeader from "./DefaultHeader";
import DefaultFooter from "./DefaultFooter";

const Product = React.lazy(() => import("../../containers/Product"));
const Contact = React.lazy(() => import("../../containers/Contact"));
const PrivacyPolicy = React.lazy(() =>
  import("../../containers/PrivacyPolicy")
);
const Clusters = React.lazy(() => import("../../containers/Clusters"));
const Cluster = React.lazy(() => import("../../containers/Cluster"));
const WhoWeAre = React.lazy(() => import("../../containers/WhoWeAre"));
const Blog = React.lazy(() => import("../../containers/Blog"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
export default function DefaultLayout(props) {
  return (
    <React.Fragment>
      <Router>
        <DefaultHeader />
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/contact" name="Contact page" exact>
              <Contact />
            </Route>
            <Route path="/privacy-policy" name="Privacy policy page" exact>
              <PrivacyPolicy />
            </Route>
            <Route path="/blog" name="blog page" exact>
              <Blog />
            </Route>
            <Route path="/who-we-are" name="Who we are" exact>
              <WhoWeAre />
            </Route>
            <Route path="/:clusterName/:id" name="Product page" exact>
              <Product />
            </Route>
            <Route path="/:id" name="Cluster page" exact>
              <Cluster />
            </Route>
            <Route path="/" name="Home" exact>
              <Clusters />
            </Route>
          </Switch>
        </React.Suspense>

        <DefaultFooter />
      </Router>
    </React.Fragment>
  );
}
