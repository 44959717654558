import React from 'react';
import './App.css';
import Helmet from 'react-helmet'
import DefaultLayout from './components/DefaultLayout/DefaultLayout';

function App() {
  document.title = "ChemClick"
  return (
    <React.Fragment>
      <Helmet>
        <title>ChemClick</title>
        <meta
          name="description"
          content="Chemclick sells diagnostic tools and laboratory equipment, and Product development: Life science sector  and algorithms are currently revolutionizing information processing, allowing businesses to get grown as a powerful presence in the uk."
        />
      </Helmet>
      <DefaultLayout />
    </React.Fragment>
  );
}

export default App;
