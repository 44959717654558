import React from "react";
import { Container, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";
import { siteName } from "../../global";

export default function DefaultFooter(props) {
  const bestProducts = [
    {
      name:
        "Monkey Platelet Derived Growth Factor Receptor Like Protein ELISA Kit",
      url:
        "https://chemclick.com/protein-factor-elisa-receptor-kit/monkey-platelet-derived-growth-factor-receptor-like-protein-elisa-kit",
    },
    {
      url:
        "https://chemclick.com/protein-factor-elisa-kit/goose-insulin-like-growth-factor-binding-protein-1-elisa-kit",
      name: "Goose Insulin Like Growth Factor Binding Protein 1 ELISA Kit",
    },
    {
      name:
        "Monkey Sushi Von Willebrand Factor Type A Egf and Pentraxin Domain Containing Protein 1 ELISA Kit",
      url:
        "https://chemclick.com/protein-factor-elisa-kit/monkey-sushi-von-willebrand-factor-type-a-egf-and-pentraxin-domain-containing-protein-1-elisa-kit",
    },
    {
      name: "Monkey Insulin Like Growth Factor Binding Protein 6 ELISA Kit",
      url:
        "https://chemclick.com/protein-factor-elisa-kit/monkey-insulin-like-growth-factor-binding-protein-6-elisa-kit",
    },
  ];
  return (
    <footer>
      <Container className="bg-dark" fluid>
        <Row>
          <Col md={4}>
            <Link to="/privacyPolicy" className="green-hover nav-link">
              Our Privacy policy
            </Link>
          </Col>
          <Col md={4}>
            <h3 className="text-light">Best Products</h3>
            <ul>
              {bestProducts.map((product) => {
                return (
                  <li key={product.url}>
                    <a className="text-secondary" href={product.url}>
                      {product.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col md={4}>
            <Link to="/contact" className="green-hover nav-link">
              Contact Us
            </Link>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-secondary mb-3">
        &copy; {new Date().getFullYear()} Copyright:{" "}
        <a href={`https://www.${siteName}.com`}> {siteName}.com </a>
      </Container>
    </footer>
  );
}
