import React, {useState} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';

import { Link, useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core'

import { siteName } from '../../global';



function DefaultHeader(props) {
  const history = useHistory();
  const [search, setSearch] = useState('')

  const handleSubmit = e => {
    e.preventDefault();
    history.push(`/search/${search}`)
  }

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">{siteName}</NavbarBrand>
        <NavbarToggler /*onClick={this.toggle}*/ />
        <Collapse
          //isOpen={this.state.isOpen}
          navbar
        >
          <Nav className="ml-auto" navbar>
            <form onSubmit={handleSubmit}>
              <TextField
              id="standard-basic"
              label="Search"
              onChange={(e)=>{setSearch(e.target.value)}}
              value={search}
              />
            </form>

            <NavItem>
              <Link to="/contact" className="green-hover nav-link">Contact</Link>
            </NavItem>
            <NavItem>
              <Link to="/blog" className="green-hover nav-link">Blog</Link>
            </NavItem>
            <NavItem>
              <Link to="/whoWeAre" className="green-hover nav-link">Who we are</Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default DefaultHeader;